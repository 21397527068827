import React, { useState } from 'react';

const FilterValue = ({ onFiltered, filter }) => {

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    onFiltered({...filter, state: newValue})
  };


  return (
    <div>
      <input type="range" min={0} max="100" value="40" className="range" />
    </div>
  );
};

export default FilterValue;