import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import useScrollSnap from "react-use-scroll-snap";
import { motion } from "framer-motion";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';


import Header from './components/Header/Header';
import Details from './components/Details/Details';
import CurrentProductSelector from './components/CurrentProductSelector/CurrentProductSelector';
import Footer from './components/Footer/Footer';
import FAQPage from './components/FAQPage/FAQPage';



function App() {
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
  const [compareList, setCompareList] = useState(JSON.parse(localStorage.getItem('compareList')) || []);
  const [products, setProducts] = useState([]);
  const scrollRef = useRef(null);
  useScrollSnap({ ref: scrollRef, duration: 1, delay: 1 });

  useEffect(() => {
    localStorage.setItem('compareList', JSON.stringify(compareList));
  }, [compareList]);

  
  useEffect(() => {
    
    if (process.env.REACT_APP_ENV === 'development') {
      fetch("./data/products.json") 
        .then((response) => response.json())
        .then((data) => setProducts(data))
        .catch((error) => console.error('Error loading products:', error));
    }
    else {
      fetch("/api/products")
      .then((response) => response.json())
      .then((data) => setProducts(data))
      .catch((error) => console.error('Error loading products DB:', error));
    }
  }, []);
  
  const addToCompareList = (product)  => {
    let copy = [...compareList]
    copy.push(product)
    setCompareList(copy)
  }

  const handleOnDeleteIndex = (index) => {
    let copy = [...compareList]
    copy.splice(index, 1)
    setCompareList(copy)
  }


  const handleOnProductSelected = (product, event) => {
    if (compareList.indexOf(product) !== -1) {
      handleOnDeleteIndex(compareList.indexOf(product))
    } else {
      addProductToCompare(product)
    }
  }

  const addProductToCompare = (product) => {
    let warnMessage = "comparator is full"
    let success = true
    if (compareList.length >= 5) {
      success = false
    }

    compareList.forEach((a)=>{
      if (a._id === product._id) {
        warnMessage = "repeated entities"
        success = false
      }
    })

    if (success) {
      addToCompareList(product)
      toast.info(product.nombre + " added to compare", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.warn(warnMessage, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  // FAQS routing
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    // Suponiendo que 'config.json' está en la carpeta 'public'
    fetch('/config.json')
      .then(response => response.json())
      .then(data => setFaqs(data.faqs));
  }, []);




  return (
    
    <div className={"w-full flex-col items-center font-poppins bg-bg"} ref={scrollRef}>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        limit={0}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Router>
            <Routes>
              {faqs.map(faq => (
                <Route key={faq} path={`/faqs/${faq}`} element={<FAQPage faq={faq} />} />
              ))}
            </Routes>
      </Router>
      <Analytics />

      <Header products={products} onProductSelected={(product, event) => handleOnProductSelected(product, event)}></Header>
      <Details products={compareList} onProductSelected={(product, event) => handleOnProductSelected(product, event)}/>
      
      <CurrentProductSelector compareList={compareList} className={'bg-orange-400'} onDeleteIndex={handleOnDeleteIndex}/>

      
      <Footer/>
      
    </div>
  );
}

export default App;
