import React, { useState, useEffect, useCallback } from 'react';

const FilterMultiSelect = ({ onFiltered, filter }) => {
  const [selectedValues, setSelectedValues] = useState([]);

  const handleSelectChange = (event) => {
    const newSelectedValues = Array.from(event.target.selectedOptions, (option) => option.value);
    setSelectedValues(newSelectedValues);
  };

  const memoizedOnFiltered = useCallback(() => {
    onFiltered((prevFilter) => ({
      ...prevFilter,
      state: selectedValues,
    }));
  }, [onFiltered, selectedValues]);

  useEffect(() => {
    memoizedOnFiltered();
  }, [memoizedOnFiltered]);

  // Ensure useEffect only runs when selectedValues changes
  useEffect(() => {
    // Your other logic here, if any
  }, [selectedValues]);

  return (
    <div className="mb-4 bg-bg text-foreground">
      <select
        id={`myMultiSelect_${filter.key}`}
        className="p-2 w-full bg-comment border-0"
        multiple
        value={selectedValues}
        onChange={handleSelectChange}
      >
        {filter.values.map((val) => (
          <option key={val} value={val}>
            {val}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FilterMultiSelect;
