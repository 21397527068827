import React, { useState } from 'react';
import ReactSlider from 'react-slider'

const FilterRange = ({onFiltered, filter}) => {
  const [minValue, setMinValue] = useState(filter.values[0])
  const [maxValue, setMaxValue] = useState(filter.values[1])
  console.log(maxValue)

  const handleChange = (a) => {
    onFiltered({...filter, values:a})
  }

  return (
    <div className={'h-20 items-center w-52'}>
          <ReactSlider
          className="horizontal-slider"
          thumbClassName={'bg-pink p-1 cursor-pointer'}
          trackClassName={'bg-comment h-2'}
          max={maxValue}
          defaultValue={[0, maxValue]}
          ariaLabel={['Leftmost thumb', 'Rightmost thumb']}
          renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
          pearling
          minDistance={0}
          onAfterChange={handleChange}
        />
    </div>

  );
};

export default FilterRange;
