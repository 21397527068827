import { Icon } from "@iconify/react"

const text_color = ['text-red', 'text-yellow', 'text-green']

export const comparatorCHR = [
    {name:'tiempo_vuelo', icon: <Icon icon={'icon-park-solid:time'}/>, unit:'min' },
    {name:'alcance', icon: <Icon icon={'ri:pin-distance-fill'}/>, unit:'m' },
    {name:'peso', icon: <Icon icon={'mdi:weight-kg'}/>, unit:'g' },
    {name:'video', icon: <Icon icon={'mdi:video'}/>, unit:'' },
    {name:'precio', icon: <Icon icon={'clarity:euro-solid'}/>, unit:'€' }
]

export const toComparator = (product) => {
    const caracteristicas = product.caracteristicas
    console.log(caracteristicas)
    const nProps = comparatorCHR.map(c=>{
        const val = product.caracteristicas[c.name]
        return {
            ...c,
            value: val
        }
        })

    return {
        id: product._id,
        img: product.imagenURL,
        link: product.product_url,
        chr: nProps,
        _product: product
    }
}
