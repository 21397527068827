import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const CurrentProductSelector = ({ compareList, onDeleteIndex, goDetailsClick }) => {
  const [productCount, setProductCount] = useState(0);

  useEffect(() => {
    // Trigger animation whenever product count changes
    if (productCount > 0) {
      // Your animation logic here
      console.log("Animate!");
    }
  }, [productCount]);

  return (
    <div className="fixed bottom-0 left-10 transform -translate-x-1/2  p-4 bg-purple m-5 rounded-lg text-center">
      {compareList.length}
    </div>
  );
};

export default CurrentProductSelector;
