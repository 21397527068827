import React, { useState } from "react";
import { motion } from "framer-motion";
import ItemCard from "../ItemCard/ItemCard";
import Reveal from "../Effects/Reveal/Reveal";

const ItemList = ({ products, onProductClicked }) => {
  const [ballPosition, setBallPosition] = useState(null);

  const handleClick = (product, event) => {
    onProductClicked(product, event);
  };

  return (
<div className="bg-white w-full h-full p-10">
    <div className="flex flex-col flex-wrap h-[800px] gap-8 overflow-x-scroll overflow-y-hidden">

                {products.map((p, idx) => (
                    <Reveal key={p.id / idx} delay={idx/60}>
                        <ItemCard
                            key={p.id + idx}
                            product={p}
                            onCardClick={(product, event) => handleClick(product, event)}
                        />
                    </Reveal>
                ))}

    </div>
</div>

  );
};

export default ItemList;
