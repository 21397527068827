import React, { useState, useEffect } from "react";
import SearchBar from "../SearchBar/SearchBar";
import Reveal from "../Effects/Reveal/Reveal";
import { Icon } from "@iconify/react";
import FilterController from "./FilterComponents/FilterController";
import FilterManager from "./FilterComponents/FilterManager";

const Filter = ({ products, setProducts }) => {

  const [expanded, setExpanded] = useState(false);
  const [categories, setCategories] = useState([]);

  let filters = []
  const main_products = [...products]


  const toggleFilterPanel = () => {
    console.log(expanded)
    setExpanded(!expanded);
  };

  const applyFilters = (filters) => {
    console.log("FILTER")
    console.log(filters)
    const filteredProducts = main_products.filter((product) => {
      const characteristics = product.caracteristicas;
      filters.forEach(filter=>{
        switch (filter.type) {
          case "select": {
            if (characteristics[filter._key] == filter.state) {
              
              console.log(product.caracteristicas[filter._key])
              return true
            } 
          }
        }
          
        
      })
      
    })
    console.log(filteredProducts)
  }


  const handleOnFilterChange = (filters) => {
    console.log(filters)
    applyFilters(filters)
  };

  // Fetch de las categorias
  const URL_CATEGORIES = "./data/categories.json";
  useEffect(() => {
    fetch(URL_CATEGORIES)
      .then((response) => response.json())
      .then((data) => setCategories(data))
      .catch((error) => console.error('Error loading products:', error));
  }, []);

  console.log(categories)
  return (

      <div className={`absolute ${expanded ? 'h-0' : 'h-5/6'} w-72 right-5 transition-all px-4 ${expanded ? 'bg-purple' : 'bg-currentLine'}  backdrop-blur-md duration-700 rounded-b-xl`}>

          <FilterManager className={`h-full ${expanded ? 'h-0' : 'h-5/6'}`} categories={categories} onFilterChange={handleOnFilterChange}/>
  
          
        <div className={'flex w-full justify-center cursor-pointer'} onClick={toggleFilterPanel}>
          <div className={` ${!expanded ? 'bg-currentLine text-foreground' : 'bg-purple'} transition-all duration-700 flex items-center p-1 text-bg rounded-b-xl h-10`}>
            <p>Filters</p>
            <Icon className={`text-4xl ${!expanded ? '' : 'rotate-180'} transition-all text-bg`} icon={'iconamoon:arrow-up-2'}></Icon>
          </div>
        </div>
      </div>
      
      

  );
};

export default Filter;
