import React, { useState, useEffect } from "react";
import Filter from "../Filter/Filter";
import ItemList from "../ItemList/ItemList";
import NavBar from "../NavBar/NavBar";
import Wave from 'react-wavify'

const Header = ({ products, onProductSelected }) => {
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  const handleOnProductSelected = (product, event) => {
    onProductSelected(product, event);
  };

  return (
    <div className={"w-full h-auto flex flex-col bg-[url('./assets/bg1.svg')] bg-cover"}>
      <Wave fill='#C62368'
        className={'absolute bottom-0 h-20'}
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 4
        }}/>
      <NavBar products={products} setProducts={setFilteredProducts}/>
      <div className={'flex'}>
      <ItemList products={filteredProducts} onProductClicked={(product, event) => handleOnProductSelected(product, event)} />
      <Filter products={products} setProducts={setFilteredProducts}/>
      </div>
      
    </div>
  );
};

export default Header;
