import React, { useState, useEffect } from "react";
import { motion, useAnimation, useMotionValue, useTransform, animate } from "framer-motion";

const WordSpinner = ({ words }) => {
  const controls = useAnimation();
  const count = useMotionValue(0);
  const rounded = useTransform(count, (latest) => words[Math.round(latest)]);
  const [animationComplete, setAnimationComplete] = useState(false);

  useEffect(() => {
    const animationControls = animate(count, words.length - 1, {
      duration: 3,
      onComplete: () => {
        setAnimationComplete(true);
      },
    });

    return animationControls.stop;
  }, []);

  return (
    <motion.div className={`transition-all ${animationComplete ? "text-pink text-2xl" : ""}`}>
      {rounded}
    </motion.div>
  );
};

export default WordSpinner;
