import React, { useState } from "react";
import { motion } from "framer-motion";
import ItemCard from "../ItemCard/ItemCard";
import Reveal from "../Effects/Reveal/Reveal";
import WordSpinner from "../Effects/WordSpinner/WordSpinner";
import SearchBar from "../SearchBar/SearchBar";

const NavBar = ({ products, setProducts }) => {
  const categoriasAmazon = [
    'Electrónicos',
    'Ropa',
    'Libros',
    'Cocina',
    'Juguetes',
    'Belleza',
    'Herramientas',
    'Deportes',
    'Muebles',
    'Hogar',
    'Salud',
    'Automotriz',
    'Música',
    'Joyas',
    'Mascotas',
    'Comestibles',
    'Bebé',
    'Exteriores',
    'Oficina',
    'Juegos',
    'Zapatos',
    'Relojes',
    'Jardín',
    'Fitness',
    'Computadoras',
    'Películas',
    'Industrial',
    'Software',
    'Cámaras',
    'Teléfonos',
    'Dron'
  ];
  return (
   <div className={'w-full h-16 bg-purple'}>
      <div className={'w-full flex h-full items-center p-2'}>
        <div className={'w-72'}>
          <div className={'flex items-center bg-bg text-foreground h-12 p-2 w-auto rounded-lg hover:bg-currentLine'}>
            <p className={'mr-1'}>Busco mi</p>
            <WordSpinner words={categoriasAmazon}></WordSpinner>
          </div>
        </div>
        
        <div className={'ml-10 w-[700px] hidden'}>
          <SearchBar/>
        </div>
      </div>
   </div>
  );
};

export default NavBar;
