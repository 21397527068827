import React, { useState, useEffect, useCallback } from 'react';
import FilterController from "./FilterController";
import Reveal from "../../Effects/Reveal/Reveal";
import { formatTag } from '../../../utils/utils';

const FilterManager = ({ categories, onFilterChange }) => {
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    setFilters(
      categories.map((c) => ({
        label: formatTag(c.key),
        _key: c.key,
        type: c.type,
        values: c.values,
        state: {},
      }))
    );
  }, [categories]);

  const handleFilterChange = useCallback((changedFilter) => {
    console.log("MANAGER")
    console.log(changedFilter)
    setFilters((prevFilters) =>
      prevFilters.map((filter) =>
        filter._key === changedFilter._key ? { ...changedFilter } : filter
      )
    );
  }, []);

  useEffect(()=>{
    onFilterChange(filters)
  }, [filters])

  return (
    <div className={'overflow-y-scroll h-full text-foreground'}>
      <button>Reset</button>
      {filters && filters.map((filter, idx) => (
        <Reveal key={idx} delay={idx / 40}>
          <FilterController
            filter={filter}
            onFiltered={handleFilterChange}
          />
        </Reveal>
        
      ))}
      
    </div>
  );
};

export default FilterManager;
