import React from "react";
import ComparatorCard from "./ComparatorCard";
import { toComparator } from "../../utils/comparatorUtils";

const ComparatorWindow = ({ products, onProductSelected}) => {
  if (!products || products.length === 0) {
    return <p></p>;
  }

  return (
    <div className={'bg-slate-100 w-full h-screen p-4 flex justify-center items-center'}>
      <div className={'flex gap-16 h-[700px]'}>
        {products.map((product=>(
          <div> 
              <ComparatorCard product={toComparator(product)} onProductSelected={onProductSelected}></ComparatorCard>
          </div>
          
        )))}
      </div>

    
    </div>
  )
};

export default ComparatorWindow;
