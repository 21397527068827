import { Icon } from "@iconify/react";

export const formatTag = (str) => {
    return str
      .replace(/_/g, " ")
      .replace(
        /\w\S*/g,
        (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
      );
};

export const specialCharacteristicsPlusIcons = [
  {name:'tiempo_vuelo', icon: <Icon icon={'icon-park-solid:time'}/>, unit:'min' },
  {name:'alcance', icon: <Icon icon={'ri:pin-distance-fill'}/>, unit:'m' },
  {name:'peso', icon: <Icon icon={'mdi:weight-kg'}/>, unit:'g' },
]