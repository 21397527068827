import React from "react";
import FilterRange from "./Type/FilterRange";
import FilterMultiSelect from "./Type/FilterMultiSelect";
import FilterValue from "./Type/FilterValue";


const FilterController = ({ filter, onFiltered }) => {
  let filter_object = <></>
  switch (filter.type) {
    case 'range':
      filter_object = <FilterRange onFiltered={onFiltered} filter={filter}></FilterRange>
      break
    case 'multiselect':
      filter_object = <FilterMultiSelect onFiltered={onFiltered} filter={filter}></FilterMultiSelect>
      break
    case 'value':
      filter_object = <FilterValue onFiltered={onFiltered} filter={filter}></FilterValue>
  } 
  

  return (
    <div className={''}>
      <div >
        <p className={'ml-2'}>{filter.label}</p>
      </div>
      {filter_object}
    </div>
  );
};

export default FilterController;
