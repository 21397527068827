import React from "react";

const Tag = ({ text, selected }) => {
  const colors = ['bg-green', 'bg-yellow', 'bg-cyan', 'bg-pink'];

  // Generate a random index to pick a color from the array
  const randomIndex = Math.floor(Math.random() * colors.length);
  const randomColor = colors[randomIndex];

  return (
    <div
      className={`items-center px-2 py-1 rounded-lg m-1 h-6 text-xs text-bg w-auto whitespace-nowrap cursor-pointer ${randomColor}`}
    >
      {text}
    </div>
  );
};

export default Tag;
