import { Icon } from "@iconify/react";
import { formatTag } from "./utils";
import { specialCharacteristicsPlusIcons } from "./utils";
import React from 'react';

const specialCharacteristics = [
    "precio",
    "tiempo_vuelo",
    "alcance",
    "peso",
];



export function getTagsFromProdcut(product) {
    return Object.keys(product.caracteristicas)
    .filter((key) => !specialCharacteristics.includes(key))
    .flatMap((curr) => {
      let obj = product.caracteristicas[curr];

      if (Array.isArray(obj)) {
        return obj.map((a) => (typeof a === "string" ? formatTag(a) : a));
      } else {
        return typeof obj === "string" ? formatTag(obj) : obj;
      }
    });
}



export function getMainPerks(product) {
  return specialCharacteristicsPlusIcons.map((curr) => {
    const val = product.caracteristicas[curr.name];
    return (
      
        {name:formatTag(curr.name), icon:curr.icon, val:val, unit:curr.unit}
      
    );
  });
}