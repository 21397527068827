import React from "react";
import { Icon } from "@iconify/react";

const SearchBar = () => {
  return (
    <div className="relative flex items-center w-full group">
      <Icon
        icon={'ph:magnifying-glass-bold'}
        className={'text-foreground left-4 absolute transition-all icon-unfocused'}
      />
      <input
        type="text"
        className={'w-full h-12 bg-black rounded-xl bg-bg text-foreground border-bg transition-all focus:bg-currentLine group focus:ring-pink border-2 focus:border-pink focus:outline-none pl-10'}
        placeholder="Search..."
      />
    </div>
  );
};

export default SearchBar;
