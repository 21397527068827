import React from "react";

const ComparatorCard = ({ product, onProductSelected}) => {
  const handleChange = (event) => {
    onProductSelected(product._product, event);
  };
    console.log(product)
  return (
    <div className={'bg-currentLineBackDrop backdrop-blur-lg w-72 rounded-3xl'}>
      <img className={'w-72 h-72 object-cover rounded-t-3xl'} src={product.img}></img>
      <div className={'flex w-full justify-center text-2xl text-foreground p-1'}><p>{product.id}</p></div>
      <div className={'flex-col items-center justify-center'}>
        {product.chr.map((p)=>(
          <div className={'p-5 border-t-2 last:border-y-2 flex text-foreground justify-start border-bg'}>
            <div className={'mx-5 text-2xl'}>{p.icon}</div>
            <div>{p.value}</div>
            <div>{p.unit}</div>
          </div>
        ))}
      </div>
      <div className={'w-full flex justify-center items-center gap-3'}>
        <a className={' bg-amazon400  p-2 rounded-full text-bg hover:bg-amazon500 transition-all'} href={product.link}>Comprar</a>
        <div onClick={handleChange} className={'text-2xl '}>X</div>
      </div>
      
    </div>
  );
};

export default ComparatorCard;
