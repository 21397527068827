import React from "react";
import ComparatorWindow from "../ComparatorComponents/ComparatorWindow";


const Details = ({ products, onProductSelected}) => {
  return (
    <div className={"bg-slate-100 w-full h-screen bg-[url('./assets/bg2.svg')] bg-cover"}>
      <ComparatorWindow products={products} onProductSelected={onProductSelected}></ComparatorWindow>
    </div>
  );
};

export default Details;
