import React, { useState, useEffect } from 'react';

function FAQPage({ faq }) {
  const [content, setContent] = useState('');

  useEffect(() => {
    // Suponiendo que los archivos HTML están en la carpeta 'public/faqs'
    fetch(`/faqs/${faq}.html`)
      .then(response => response.text())
      .then(html => setContent(html));
  }, [faq]);

  return <div dangerouslySetInnerHTML={{ __html: content }} />;
}

export default FAQPage;
