import React, { useState } from "react";
import { getMainPerks, getTagsFromProdcut } from "../../utils/productConverter";
import Tag from "../Tag/Tag";
import { Icon } from "@iconify/react";
import CircleRating from "./CircleRating";

const ItemCard = ({ product, onCardClick }) => {
  const [selected, setSelected] = useState(false);
  const handleChange = (event) => {
    onCardClick(product, event);
    setSelected(!selected);
  };

  const tagStrArray = getTagsFromProdcut(product);

  return (
    <div
      className={
        "relative p-2 bg-currentLine backdrop-blur-sm h-[200px] w-[300px] flex-col rounded-xl hover:bg-slate-300 hover:shadow-lg transition-all group"
      }
    >
      <div className={"flex relative"}>
        <img
          src={product.imagenURL}
          alt="Product Image"
          className={"transition-all w-32 h-32 rounded-lg object-cover"}
        />
        <div className={"transition-all w-full p-2 flex-col justify-evenly"}>
          <div onClick={handleChange} className={`w-full flex justify-end text-2xl ${!selected ? 'text-foreground' : 'text-red'}`}>
            <div className={'active:rotate-90 transition-all'}>
            {!selected ? <Icon icon={'gg:add'}/> : <Icon icon={'gg:remove'}/>}
            </div>
            
          </div>
          <h1 className={'text-foreground text-sm'}>{product._id}</h1>
          <div className={'w-full flex items-center pt-4'}>
            <a href={product.product_url} className={"w-full text-center"} target="_blank" rel="noopener noreferrer">
                <div className={"bg-amazon400 hover:bg-amazon500 transition-all text-black font-bold py-2 px-4 rounded-full"}>
                  <p>
                    {product.caracteristicas.precio == "" ? (
                      <p>Producto no disponible</p>
                    ) : (
                      <p>{product.caracteristicas.precio} €</p>
                    )}
                  </p>
                </div>
            </a>
          </div>
        </div>
      </div>
      <div className={" bg-comment rounded-lg mt-2 shadow-md transition-all"}>
        <div className={"h-[50px] flex items-center"}>
            <CircleRating rating={product.caracteristicas.valoracion*2}/>
            <div className={'w-full justify-evenly flex'}>
              {getMainPerks(product).map((item)=>(
                <div className={'flex items-center text-2xl text-foreground'}>
                  <p>{item.icon}</p>
                  <p className={'text-sm ml-1'}>{item.val} {item.unit}</p>
                </div>
              ))}
            </div>
            

        </div>
     
      </div>
    </div>
  );
};

export default ItemCard;
