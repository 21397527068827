import React from 'react';

const CircleRating = ({rating}) => {
  const total = 10;
  const radius = 20;
  const circumference = 2 * Math.PI * radius;
  const progress = (rating / total) * circumference;

  return (
    <div className="flex items-center justify-center h-screen">
      <svg className="h-20" viewBox="0 0 100 100">
        <circle
          className="text-gray-300 stroke-current"
          cx="50"
          cy="50"
          r={radius}
          strokeDasharray={circumference}
          strokeWidth="5"
          fill="none"
        />
        <circle
          className={rating >= 5 ? (rating >= 8 ? "text-green stroke-current" : "text-yellow stroke-current"): "text-red stroke-current"}
          cx="50"
          cy="50"
          r={radius}
          strokeDasharray={circumference}
          strokeDashoffset={circumference - progress}
          strokeWidth="5"
          fill="none"
        />
      </svg>
      <p className={'text-foreground absolute'}>{rating}</p>
    </div>
  );
};

export default CircleRating;